.TZSelector {
    width:100%;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 25px;
    height: 50px;
    border: 0px;
    appearance: none;
}
.TZSelector:hover {
    background-color: #DDDDDD;

}
.item {
    background-color:#FFFFFF;
}